import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ddb7ba6c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "body"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  style: {
    "border-radius": "15px",
    "margin": "0px 15px 15px 15px",
    "padding": "15px 15px 15px 15px",
    "background-color": "white"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_swipe_item = _resolveComponent("van-swipe-item");
  const _component_van_swipe = _resolveComponent("van-swipe");
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_cell_group = _resolveComponent("van-cell-group");
  const _component_van_radio = _resolveComponent("van-radio");
  const _component_van_radio_group = _resolveComponent("van-radio-group");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_form = _resolveComponent("van-form");
  const _component_van_button = _resolveComponent("van-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_swipe, {
    autoplay: 2500,
    "lazy-render": ""
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.images, image => {
      return _openBlock(), _createBlock(_component_van_swipe_item, {
        key: image
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: image.url,
          style: {
            "width": "100%"
          }
        }, null, 8, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [_createVNode(_component_van_cell, {
      title: "姓名",
      value: $data.myinfo.names
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      title: "身份证",
      value: $data.myinfo.idCard
    }, null, 8, ["value"]), _createVNode(_component_van_cell, {
      title: "联系电话",
      value: $data.myinfo.phone
    }, null, 8, ["value"])]),
    _: 1
  }, 512), [[_vShow, !$data.isEdit]]), _createVNode(_component_van_cell_group, null, {
    default: _withCtx(() => [_createVNode(_component_van_form, {
      ref: "form"
    }, {
      default: _withCtx(() => [_withDirectives(_createElementVNode("div", null, [_createVNode(_component_van_field, {
        name: "checkbox",
        label: "区域",
        rules: [{
          required: true,
          message: '请填写区域'
        }]
      }, {
        input: _withCtx(() => [_createVNode(_component_van_radio_group, {
          modelValue: $data.myinfo.area,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.myinfo.area = $event),
          direction: "horizontal"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_radio, {
            name: "营山"
          }, {
            default: _withCtx(() => [_createTextVNode("营山")]),
            _: 1
          }), _createVNode(_component_van_radio, {
            name: "蓬安"
          }, {
            default: _withCtx(() => [_createTextVNode("蓬安")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.names,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.myinfo.names = $event),
        label: "姓名",
        rules: [{
          required: true,
          message: '请填写姓名'
        }]
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.idCard,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.myinfo.idCard = $event),
        label: "身份证",
        required: "",
        rules: $data.rules.idCardNumber,
        maxlength: 18
      }, null, 8, ["modelValue", "rules"]), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.phone,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.myinfo.phone = $event),
        label: "联系电话",
        required: "",
        type: "tel",
        rules: [{
          required: true,
          message: '请填写联系电话'
        }],
        maxlength: 11
      }, null, 8, ["modelValue"])], 512), [[_vShow, $data.isEdit && $data.type == 'add']]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_van_field, {
        name: "checkbox",
        label: "区域",
        rules: [{
          required: true,
          message: '请填写区域'
        }]
      }, {
        input: _withCtx(() => [_createVNode(_component_van_radio_group, {
          modelValue: $data.myinfo.area,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.myinfo.area = $event),
          direction: "horizontal"
        }, {
          default: _withCtx(() => [_createVNode(_component_van_radio, {
            name: "营山"
          }, {
            default: _withCtx(() => [_createTextVNode("营山")]),
            _: 1
          }), _createVNode(_component_van_radio, {
            name: "蓬安"
          }, {
            default: _withCtx(() => [_createTextVNode("蓬安")]),
            _: 1
          })]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.names,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.myinfo.names = $event),
        label: "姓名",
        rules: [{
          required: true,
          message: '请填写姓名'
        }]
      }, null, 8, ["modelValue"]), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.idCard,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => $data.myinfo.idCard = $event),
        label: "身份证",
        required: "",
        rules: $data.rules.idCardNumber,
        maxlength: 18
      }, null, 8, ["modelValue", "rules"]), _createVNode(_component_van_field, {
        modelValue: $data.myinfo.phone,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => $data.myinfo.phone = $event),
        label: "联系电话",
        required: "",
        type: "tel",
        rules: [{
          required: true,
          message: '请填写联系电话'
        }],
        maxlength: 11
      }, null, 8, ["modelValue"])], 512), [[_vShow, $data.isEdit && $data.type == 'edit']])]),
      _: 1
    }, 512)]),
    _: 1
  })]), _withDirectives(_createVNode(_component_van_button, {
    round: "",
    type: "primary",
    onClick: _cache[8] || (_cache[8] = $event => $options.edit()),
    class: "button"
  }, {
    default: _withCtx(() => [_createTextVNode("修改")]),
    _: 1
  }, 512), [[_vShow, !$data.isEdit]]), _withDirectives(_createVNode(_component_van_button, {
    round: "",
    type: "success",
    onClick: _cache[9] || (_cache[9] = $event => $options.save()),
    class: "button"
  }, {
    default: _withCtx(() => [_createTextVNode("保存")]),
    _: 1
  }, 512), [[_vShow, $data.isEdit]]), _withDirectives(_createVNode(_component_van_button, {
    round: "",
    type: "danger",
    onClick: _cache[10] || (_cache[10] = $event => $options.del()),
    class: "button"
  }, {
    default: _withCtx(() => [_createTextVNode("删除")]),
    _: 1
  }, 512), [[_vShow, $data.type == 'edit']])]);
}