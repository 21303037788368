import "core-js/modules/es.array.push.js";
// import routes from '../router/routes'

import { isCnIDCard } from "@/api/validator";
import { showConfirmDialog, showToast } from 'vant';
import dateBox from '@/components/dateBox';
import moment from 'moment';
export default {
  name: 'index',
  components: {
    dateBox
  },
  data() {
    return {
      images: [{
        id: 1,
        url: '/assets/o1.jpg'
      }, {
        id: 2,
        url: '/assets/o2.jpg'
      }, {
        id: 3,
        url: '/assets/o3.jpg'
      }],
      keyword: '',
      yfm: false,
      type: "add",
      showAll: false,
      isEdit: false,
      showHos: false,
      showPopover: false,
      showAction: false,
      actions: [{
        text: '选项一'
      }, {
        text: '选项二'
      }, {
        text: '选项三'
      }],
      activeNames: ['1'],
      user: {},
      myinfo: {
        names: '',
        idCard: '',
        schoolId: '1',
        school: '老年人',
        grade: '老年人',
        gradeBz: '1'
      },
      defaultinfo: {
        avater: '1',
        remarks: '1',
        names: '',
        idCard: '',
        menstruation: '1',
        hospitalId: '1'
      },
      myplans: [{
        id: 1,
        name: '11111'
      }, {
        id: 2,
        name: '222222'
      }, {
        id: 3,
        name: '3333333'
      }],
      sex: ['未知', '男', '女'],
      planTemp: {},
      hos: [{
        id: 1,
        name: '南充市身心医院'
      }],
      rules: {
        idCardNumber: [{
          required: true,
          message: "身份证不能为空",
          trigger: "blur"
        }, {
          validator: isCnIDCard,
          message: "身份证未能通过验证"
        }],
        ty: [{
          required: true,
          message: '身份证不能为空',
          trigger: 'blur'
        }],
        area: [{
          required: true,
          message: '区域不能为空',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    yfmChange() {
      console.log(this.$data.yfm);
      if (!this.$data.yfm) {
        this.myinfo.finishDate = null;
      }
    },
    edit() {
      console.log(123);
      this.isEdit = true;
    },
    getHos() {
      var that = this;
      that.$http.get('/api/ycf/getHos', null, '正在检索').then(result => {
        console.log(result);
        if (result.data) {
          console.log(result);
          result.data.forEach(element => {
            element.text = element.hospital;
            element.value = element.id;
          });
          this.hos = result.data;
          this.hos2 = this.hos;
        } else {
          //this.showQYWXCode();
        }
      });
    },
    getMyinfo(id) {
      var that = this;
      that.$http.get('/api/old/getStus', {
        id: id
      }, '正在检索').then(result => {
        console.log(result);
        if (result.data) {
          console.log(result);
          result.data.forEach(element => {
            if (element.id == id) {
              that.myinfo = element;
              if (element.finishDate) {
                this.$data.yfm = true;
              }
            }
          });
        } else {
          //this.showQYWXCode();
        }
      });
    },
    HosConfirm({
      selectedOptions
    }) {
      this.myinfo.hospitalId = selectedOptions[0].value;
      this.myinfo.hospital = selectedOptions[0].text;
      this.showHos = false;
    },
    save() {
      var that = this;
      var url = '/api/old/addStu';
      var type = this.$route.query.type;
      switch (type) {
        case 'edit':
          url = '/api/old/updateStu';
          break;
      }
      this.$refs.form.validate().then(() => {
        that.$http.post(url, this.$data.myinfo, '正在提交数据').then(result => {
          console.log(result);
          if (result.data) {
            console.log(result);
            this.myinfo = result.data;
            this.isEdit = false;
            this.$router.push('/old/myinfos');
          } else {
            showToast(result.msg);
          }
        });
      }).catch(er => {
        console.log(er);
      });
    },
    del() {
      var that = this;
      showConfirmDialog({
        title: '确认',
        message: '确定要删除么?',
        // theme: 'round-button',
        width: '80%'
      }).then(() => {
        // on confirm
        this.$refs.form.validate().then(() => {
          that.$http.get('/api/old/delStu?id=' + that.$route.query.id, null, '正在提交数据').then(result => {
            console.log(result);
            if (result.success) {
              this.$router.push('/old/myinfos');
            } else {
              showToast(result.msg);
            }
          });
        });
        // on close
      }).catch(() => {
        // on cancel

        showToast('取消');
      });
    },
    // date为相加前的时间， days 为 需要相加的天数
    addDate(date, days) {
      var date = new Date(date);
      date.setDate(date.getDate() + days);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var mm = "'" + month + "'";
      var dd = "'" + day + "'";
      if (mm.length == 3) {
        month = '0' + month;
      }
      if (dd.length == 3) {
        day = '0' + day;
      }
      var time = year + '-' + month + '-' + day;
      return time;
    }
  },
  created() {},
  mounted() {
    this.$data.type = this.$route.query.type;
    this.$data.isEdit = true;
    console.log('mounted');
    var userstr = localStorage.getItem('user');
    var user = JSON.parse(userstr);
    if (user.userinfo) {
      this.myinfo = user.userinfo;
    } else {
      this.myinfo.avatar = user.user.avatar;
      this.myinfo.nickname = user.user.nickname;
    }
    // console.log(this.$data.type);
    if (this.$data.type == 'add') {} else if (this.$data.type == 'edit') {
      this.getMyinfo(this.$route.query.id);
    }
    this.$data.user = user.user;
    // this.getMyinfo();
    this.getHos();
  },
  watch: {
    keyword: function (newval) {
      console.log(newval);
      this.hos2 = this.hos.filter(f => f.hospital.indexOf(this.keyword) > -1);
    },
    'myinfo.menstruation': function (newval) {
      this.myinfo.deliverDate = this.addDate(newval, 280);
      console.log(this.myinfo.deliverDate);
    },
    'myinfo.deliverDate': function (newval) {
      this.myinfo.menstruation = this.addDate(newval, -280);
      console.log(this.myinfo.menstruation);
    }
  }
};